.svgee-full-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.svgee-top-nav-bar {
  /* background-color: #15b7b9; */
  /* background-color: #061621; */
  /* background-color: #1c2026; */
  /* background-color: #89979B; */
  /* background-color: #21313C; */
  background-color: #15b7b9;
  position: relative;
  text-align: right;
  width: 100%;
  
  /* vertical-align: middle; */
  /* align-items: center; */
}

.svgee-top-nav-link {
  color: #fff;
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 1em;
  margin-right: 30px;
  padding: 12px 0px;
  position: relative;
  text-decoration: none;
}

.svgee-top-nav-link:last-child {
  margin-right: 40px;
}

.svgee-top-nav-link:hover {
  cursor: pointer;
}

.svgee-top-nav-link:before {
  background-color: #fff;
  bottom: 10px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: all 0.1s ease-in-out 0s;
  -webkit-transition: all 0.1s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}

.svgee-top-nav-link:hover:before {
  transform: scaleX(1);
  visibility: visible;
  -webkit-transform: scaleX(1);
}
