.svgee-docs-container {
  position: relative;
  margin-bottom: 20px;
  margin-left: 20%;
  margin-top: 60px;
  width: 60%;
}

.svgee-docs-container pre {
  background-color: #333;
  border-radius: 1px;
  color: #f5f5f5;
  overflow: auto;
  padding: 10px;
}